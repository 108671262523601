import React, { useEffect } from 'react';
import { useScreenShare } from './ScreenshareContext';

const Viewer = ({ setScreenShareVidRef }) => {
    const { stream } = useScreenShare();

    useEffect(() => {
        let intervalId;
        let timeoutId;

        if (stream) {
            const videoTrack = stream.getVideoTracks()[0];
            const imageCapture = new ImageCapture(videoTrack);

            const captureFrame = async () => {
                if (videoTrack.readyState === 'live') {
                    try {
                        const bitmap = await imageCapture.grabFrame();
                        const canvas = document.createElement('canvas');
                        canvas.width = bitmap.width;
                        canvas.height = bitmap.height;
                        const context = canvas.getContext('2d');
                        context.drawImage(bitmap, 0, 0, canvas.width, canvas.height);

                        // Convert canvas to base64 and send to server
                        const base64Data = canvas.toDataURL('image/jpeg');
                        setScreenShareVidRef(base64Data);
                        // Optionally send this data through the socket
                        // updateSocket('screenShareVidRef', base64Data);
                    } catch (err) {
                        console.error('Error capturing frame:', err);
                    }
                }
            };

            // Delay the initial capture by 3 seconds
            timeoutId = setTimeout(() => {
                captureFrame();

                // Start the interval after the initial delay
                intervalId = setInterval(captureFrame, 3 * 60 * 1000); // 3 minutes
            }, 3000); // 3-second delay
        }

        // Cleanup interval on component unmount
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [stream, setScreenShareVidRef]);

    return <div></div>;
};

export default Viewer;
