import React, { useEffect, useState } from 'react';
import instructionRead from './instruction.svg';
import question from './question.svg';
import timer from './timer.svg';
import Button from '../../../commonComponent/Button';
import { formatTime, generateUrl, getAssetSrc, isMobileOrTabletDevice } from '../../../../utils/helper';
import { ASSET_NAMES } from '../../../../common/constants';
import { PRIVACY_POLICY, TERMS_AND_CONDITION } from '../../../../utils/constants';
const InstructionInfo = ({ form, setForm, handleJoin }) => {
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setScreenHeight(window.innerHeight);
        };

        // Attach the event listener to update dimensions when the window is resized
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures the effect runs only once after initial render
    return (
        <div
            className={`instruction-container-box instruction-box${isMobileOrTabletDevice() ? ' instruction-box-mobile' : ''}`}
        >
            <div className="interview-instructions">
                <div className="instruction-title">Few things before you start!</div>
                <div className="instruction-display-main-div">
                    <p className="instruction-display-div">
                        <span className="fw-6" style={{ lineHeight: '22px' }}>
                            <img src={timer} alt={` ${formatTime(form?.totalDuration)}  mins Interview Duration`} />
                            {` ${formatTime(form?.totalDuration)}  mins Interview Duration`}
                        </span>
                    </p>
                    <p className="instruction-display-div">
                        <span className="fw-6" style={{ lineHeight: '22px' }}>
                            <img src={question} alt={` ${form?.totalQuestion} Total Questions`} />
                            {` ${form?.totalQuestion} Total Questions`}
                        </span>
                    </p>
                </div>
                <div className="instruction-text">
                    <p>
                        <img src={instructionRead} alt="Instruction" /> Please follow these instructions carefully to
                        ensure a smooth and successful interview process:
                    </p>
                    <ul style={{ maxHeight: screenHeight - 434, overflowY: 'auto', minHeight: 80 }}>
                        <li>There are {form?.totalQuestion} questions in the interview.</li>
                        <li>Please use Chrome browser and laptop for the best interview experience.</li>
                        <li>Kindly ensure that you disconnect the VPN before initiating the interview.</li>
                        <li>
                            Once the question is skipped, you can't go back. The response can only be recorded once.
                        </li>
                        <li>
                            After reading each question, you will be given some time to prepare your response before the
                            recording begins.
                        </li>
                        <li>Each question will have a set amount of time to record your response.</li>
                        <li>
                            Your response will be automatically submitted when the time limit is reached or you can
                            submit your response and proceed to the next question.
                        </li>
                        <li>It's mandatory to switch on audio and video for the interview process.</li>
                        <li>
                            Any attempt to manipulate or misuse the interview platform may result in disqualification.
                        </li>
                        <li>Please do not refresh the page during the interview.</li>
                        <li>
                            If your interview is interrupted due to network or some other issues, you may re-attempt it
                            up to two times within one hour.
                        </li>
                        <li>
                            The interview shall be recorded throughout the interview for quality and monitoring
                            purposes.
                        </li>
                    </ul>
                    <p className="mb-1">
                        If you encounter any technical issues during the interview, please contact our technical support
                        team at support@incruiter.com for assistance.
                    </p>
                    <p className="mt-2 mb-1">
                        Thank you for participating. Your responses will help us assess your skills for the{' '}
                        {form?.positionTitle} position.
                    </p>
                    <p className="mt-2 mb-1">Good luck!</p>
                </div>
            </div>
            <div className="mt-3">
                <ConsentCheckbox
                    labelText="I consent to the interview recording and the collection of my personal data as per"
                    url={PRIVACY_POLICY}
                    assetAlt="Privacy Policy"
                    assetName={ASSET_NAMES.HYPERLINK}
                    formKey="consent"
                    form={form}
                    setForm={setForm}
                    id="consent"
                />
                <ConsentCheckbox
                    labelText="I agree with the"
                    url={TERMS_AND_CONDITION}
                    assetAlt="Terms and Conditions"
                    assetName={ASSET_NAMES.HYPERLINK}
                    formKey="instructionAccepted"
                    form={form}
                    setForm={setForm}
                    id="accept"
                />
                <Button
                    text="Start Interview"
                    className="candidate-btn"
                    disabled={!form?.instructionAccepted || !form?.consent}
                    onClick={handleJoin}
                />
            </div>
        </div>
    );
};

const ConsentCheckbox = ({ labelText, url, assetAlt, assetName, formKey, form, setForm, id }) => {
    const handleChange = () => {
        setForm((prevState) => ({
            ...prevState,
            [formKey]: !form[formKey],
        }));
    };

    return (
        <div className="instruction-check">
            <input
                type="checkbox"
                className="instruction-input"
                checked={form?.[formKey] || false}
                onChange={handleChange}
                id={id}
            />
            <label className="instruction-label" htmlFor={id}>
                <span>{labelText} </span>
                <a className="privacy-policy" href={generateUrl(url)} target="_blank" rel="noopener noreferrer">
                    <span>{assetAlt}</span>
                    <img className="position-absolute" src={getAssetSrc(assetName)} alt={assetAlt} />
                </a>
            </label>
        </div>
    );
};
export default InstructionInfo;
