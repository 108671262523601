import React from 'react';
import { Box, Flex } from '@100mslive/roomkit-react';
import { FOFPage } from './components/FOFPage';
import { Header } from '../Header';
const Other = ({ page }) => {
    let ViewComponent;
    if (page === 'fofPage') {
        ViewComponent = FOFPage;
    }
    if (!ViewComponent) {
        return null;
    }
    return (
        <Flex css={{ size: '100%', height: '100vh', background: '#2e2e41' }} direction="column">
            <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid="header">
                <Header />
            </Box>
            <ViewComponent />
        </Flex>
    );
};

export default Other;
