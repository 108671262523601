import React from 'react';
import { Flex, styled, Text } from '@100mslive/roomkit-react';
import FOFBanner from '../../../assets/images/404-banner.svg';
export const FOFPage = () => {
    return (
        <Flex direction="column" css={{ width: '100vw', height: 'calc(100% - 64px)' }}>
            <Flex
                justify="center"
                direction="column"
                align="center"
                css={{ bg: '#2E2E41', flex: '1 1 0', position: 'relative' }}
            >
                <Text
                    variant="body1"
                    css={{
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '40px',
                        lineHeight: '55px',
                        color: '#9396A0',
                        textAlign: 'center',
                    }}
                >
                    OOPS!
                    <br />
                    Error 404 - Page not found
                </Text>
                <Text
                    variant="body1"
                    css={{
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '18px',
                        lineHeight: '25px',
                        color: '#FFFFFF',
                    }}
                >
                    We could not find what you were looking for
                </Text>
                <br />
                <LogoImg src={FOFBanner} alt="InCruiter Logo" width={615} height={354} />
            </Flex>
        </Flex>
    );
};

const LogoImg = styled('img', {
    p: '$2',
});
