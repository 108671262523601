import React from 'react';
import Modal from '../../commonComponent/Modal/Modal';

const InterruptionPopUp = ({ handleContinueClick, handleEndCall, showModal }) => {
    const InterviewInterruptContent = () => (
        <div className="d-flex flex-column gap-10 justify-content-center align-items-center mt-2">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="countdown-body">
                    Oops! It seems like there's been a hiccup in your network connection. Unfortunately the interview
                    has been interrupted.
                </div>
                <div className="countdown-body">Do you wish to continue?</div>
            </div>
        </div>
    );

    return (
        <Modal
            type={1}
            isOpen={true}
            headerText="Interview Interrupted!"
            buttonText="Continue"
            handleClick={handleContinueClick}
            buttonStyle="modal-btn"
            primaryBtnText="End Call"
            primaryBtnStyle="primary-modal-btn"
            handlePrimaryBtnClick={() => {
                handleEndCall(false, 'Interview Interruption Call End');
            }}
            Content={InterviewInterruptContent}
            buttonsDivClass="countdown-buttons"
            headerLine
        />
    );
};

export default InterruptionPopUp;
